/* barlow-300 - latin-ext_latin */
@font-face {
  font-family: 'Barlow Light';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../Static/Fonts/barlow-v5-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../Static/Fonts/barlow-v5-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-300.svg#Barlow') format('svg'); /* Legacy iOS */
}

/* barlow-300italic - latin-ext_latin */
@font-face {
  font-family: 'Barlow Light Italic';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../Static/Fonts/barlow-v5-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../Static/Fonts/barlow-v5-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-300italic.svg#Barlow') format('svg'); /* Legacy iOS */
}

/* barlow-regular - latin-ext_latin */
@font-face {
  font-family: 'Barlow Regular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../Static/Fonts/barlow-v5-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../Static/Fonts/barlow-v5-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-regular.svg#Barlow') format('svg'); /* Legacy iOS */
}

/* barlow-500 - latin-ext_latin */
@font-face {
  font-family: 'Barlow Medium';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../Static/Fonts/barlow-v5-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../Static/Fonts/barlow-v5-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-500.svg#Barlow') format('svg'); /* Legacy iOS */
}

/* barlow-500italic - latin-ext_latin */
@font-face {
  font-family: 'Barlow Medium Italic';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../Static/Fonts/barlow-v5-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../Static/Fonts/barlow-v5-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-500italic.svg#Barlow') format('svg'); /* Legacy iOS */
}

/* barlow-700 - latin-ext_latin */
@font-face {
  font-family: 'Barlow Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../Static/Fonts/barlow-v5-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../Static/Fonts/barlow-v5-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-700.svg#Barlow') format('svg'); /* Legacy iOS */
}

/* barlow-700italic - latin-ext_latin */
@font-face {
  font-family: 'Barlow Bold Italic';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../Static/Fonts/barlow-v5-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../Static/Fonts/barlow-v5-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-700italic.svg#Barlow') format('svg'); /* Legacy iOS */
}

/* barlow-800 - latin-ext_latin */
@font-face {
  font-family: 'Barlow ExtraBold';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../Static/Fonts/barlow-v5-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../Static/Fonts/barlow-v5-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-800.svg#Barlow') format('svg'); /* Legacy iOS */
}

/* barlow-800italic - latin-ext_latin */
@font-face {
  font-family: 'Barlow ExtraBold Italic';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('../Static/Fonts/barlow-v5-latin-ext_latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../Static/Fonts/barlow-v5-latin-ext_latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-800italic.woff') format('woff'), /* Modern Browsers */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../Static/Fonts/barlow-v5-latin-ext_latin-800italic.svg#Barlow') format('svg'); /* Legacy iOS */
}
